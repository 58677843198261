<template>
  <div>
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="海报类型" slot="copyType" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.posterType === '01' ? '换新活动海报' : '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="海报图片" slot="posterImg" align="center">
        <template slot-scope="{ row }">
          <img style="width: 100px;height: 50px;" :src="row.posterImg" mode="widthFix" @click="seeSampleImage(row.posterImg)" alt="">
        </template>
      </el-table-column>
      <el-table-column label="最近上架时间" slot="lastPutTime" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.listingTime || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="默认排序" slot="sortnum" width="150px" align="center">
        <template slot-scope="{ row }">
          <el-input-number size="small" v-model="row.sort" @change="handleChangeNum(row)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="addOneMenu(row)">编辑</el-button>
          <el-button size="mini" v-if="!row.isListing" type="success" round @click="handleBtn(row)">上架</el-button>
          <el-button size="mini" v-else type="danger" round @click="handleBtn(row)">下架</el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 编辑海报 -->
    <el-dialog title='编辑海报' :visible.sync="csetionShow" :close-on-click-modal="false" width="700px">
      <el-form :model="createinitData" :rules="createformRules" ref="ruleForm" label-width="106px">
        <el-form-item label="海报类型:" label-width="110px" prop="posterType">
          <el-select v-model="createinitData.posterType" placeholder="请选择">
            <el-option 
            v-for="item in posterTypeoptions" 
            :key="item.value" 
            :label="item.label" 
            :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="海报图片:" label-width="110px" prop="posterImg">
          <ElImgUpload placeTxt="最多上传1张图片，大小不超过2M，图片尺寸为700px *100px，格式为JPG，PNG或GIF" :exist-image="createinitData.posterImg" @handleDelete="handleUploadDelete"
            @handleChange="handleUploadChange($event)" />
        </el-form-item>
        <el-form-item label="上传压缩文件:" label-width="110px" prop="posterOriginalFile">
            <FielUpload placeTxt="仅支持.zip,.rar格式,单次上传大小不超过10M" :existFile="createinitData.posterOriginalFile" @handleDelete="handleUploadfileDelete"
            @handleChange="handleUploadFileChange($event)" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('ruleForm')">取 消</el-button>
        <el-button size="small" type="primary" :loading="btnLoading" @click="submitAdd('ruleForm')">确认</el-button>
      </span>
    </el-dialog>
    <!-- 下架 -->
    <el-dialog title="操作提示" :visible.sync="usedDialogVisible" :close-on-click-modal="false" width="400px">
      <div style="
                  font-size: 16px;
                  color: #666666;
                  line-height: 50px;
                ">
        <div style="text-align: center;">是否确认{{ isPut ? '下架' : '上架' }}该海报？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="usedDialogVisible = false">取消</el-button>
        <el-button type="primary" @click.native="usedSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :on-close="() => {
      showViewer = false;
    }
      " :url-list="imgList" />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import FielUpload from "../../../components/global/components/elements/uploadFile";
import _api from "@/utils/request";
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import moment from "moment";
import { MessageBox } from "element-ui";
export default {
  components: { ElImgUpload, ElImageViewer,FielUpload },
  data() {
    return {
      paramsExcel:{},
      posterTypeoptions:[{
          value: '01',
          label: '换新活动海报'
        },],
      usedDialogVisible: false,
      imgList: [],
      showViewer: false,
      btnLoading: false,
      csetionShow: false,
      createinitData: {
        posterImg: "",
        posterOriginalFile: "",
        posterType: '01'
      },
      createformRules: {
        posterType: [
          { required: true, message: '请选择海报类型', trigger: 'change' }
        ],
        posterOriginalFile: [
          {
            required: true,
            trigger: "blur",
            message: "请上传压缩文件",
            max: 200,
          },
        ],
        posterImg: [
          {
            required: true,
            trigger: "blur",
            message: "请上传海报图片",
          },
        ],
       
      },
      initData: {
        entryTime: [
          moment()
            .day(moment().day() - 6)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      datalist: [],
      tableColumns: [
        { label: "编号", prop: "posterNo" },
        { slotName: "copyType" },
        { slotName: "posterImg" },
        { label: "添加人", prop: "createAdminName" },
        { label: "添加时间", prop: "createTime" },
        { slotName: "lastPutTime" },
        { slotName: "sortnum" },
        { slotName: "operation" },
      ],
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      copyNo: "",
      isPut: false,
    };
  },
  created() {
    this.handleCurrentChange();
  },
  mounted() { },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.handleCurrentChange();
    },
  },
  methods: {
     //排序
     handleChangeNum(val) {
      const params = {
        id: val.id,
        sort: val.sort
      };
      _api.updatepoterSort(params).then(res => {
        if (res.code === 1) {
            this.handleCurrentChange();
            this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //编辑
    addOneMenu(row) {
      console.log(row);
      this.createinitData = row
      this.csetionShow = true
    },
    //确认下架
    usedSuccess() {
      _api.updateIsListing({
        isListing: this.isPut ? false : true,
        id: this.copyNo
      }).then(res => {
        if (res.code === 1) {
          this.usedDialogVisible = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          });
          this.handleCurrentChange();
        }
      })
    },
    // 查看示例图大图
    seeSampleImage(posterImg) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(posterImg);
    },
    // 确认添加海报
    submitAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true
          console.log(this.createinitData);
          _api.saveposterInfo(this.createinitData).then(res => {
            if (res.code === 1) {
              this.csetionShow = false
              this.$message({
                message: '编辑成功！',
                type: 'success'
              });
              this.handleCurrentChange(1);
            }
            this.btnLoading = false
          }).catch(err => {
            this.btnLoading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    //删除图片
    handleUploadDelete() {
      this.createinitData.posterImg = "";
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadChange(url) {
      this.createinitData.posterImg = url;
    },
       //删除文件
       handleUploadfileDelete() {
      this.createinitData.posterOriginalFile = "";
    },
      // 上传文件后，将信息保存到对应参数中
      handleUploadFileChange(url) {
      console.log(url);
      this.createinitData.posterOriginalFile = url;
    },
    //添加海报弹窗关闭
    resetForm() {
      this.csetionShow = false
    },
    // 导出订单方法
    exportExcel(e) {
      let redenvelopeslist = [];
      redenvelopeslist = {
        startTime: this.datalist.startTime || this.entryTime[0],
        endTime: this.datalist.endTime || this.entryTime[1],
        pageNum: this.page3.pageNum,
        pageSize: 10,
        staffName: this.datalist.staffName || "",
        staffMobile: this.datalist.staffMobile || "",
      };

      console.log(redenvelopeslist);
      _api.redpacketdetailexcel(redenvelopeslist).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            let start = moment(Number(redenvelopeslist.startTime)).format(
              "YYYY.MM.DD"
            );
            let end = moment(Number(redenvelopeslist.endTime)).format(
              "YYYY.MM.DD"
            );
            const fileName =
              `可提现奖励明细.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
    //   切换页
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        adminAccount: this.SeachParams.adminAccount || "",
        endTime: this.SeachParams.endTime,
        startTime: this.SeachParams.startTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true;
      _api
        .posterListdata(baseRequest)
        .then((res) => {
          console.log(res);
          if (res.code === 1 && res.data && res.data.records) {
            this.seachDataList = res.data.records;
            this.page.total = res.data.total;
            this.page.pageNum = res.data.current;
            this.loading = false;
          }
        })
        .finally(() => {
          this.loading = false;
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    // 上下架
    handleBtn(row) {
      this.copyNo = row.id
      this.isPut = row.isListing
      this.usedDialogVisible = true
    },
  },
};
</script>

<style lang="scss" scoped>
.staffNamebox {
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 5px;
  color: #fff;
}

.content_copy {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.tip {
  color: #FF687B;
}

.andleShowbox {
  border: 1px solid #0981ff;
  width: 200px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: 20px;

  .andleShowstyle {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0981ff;
    cursor: pointer;
    user-select: none;
  }

  .andleShowcolor {
    background-color: #0981ff;
    color: #fff;
  }
}
</style>
